import { apiVersion, dataset, projectId, useCdn } from "./config";
import {
  postquery,
  limitquery,
  paginatedquery,
  configQuery,
  singlequery,
  pathquery,
  allauthorsquery,
  authorsquery,
  postsbyauthorquery,
  postsbycatquery,
  catpathquery,
  catquery,
  searchquery,
} from "./groq";
import { createClient, type SanityClient } from "next-sanity";

if (!projectId) {
  console.error("The Sanity Project ID is not set. Check your environment variables.");
}

let clientInstance: { [key: string]: SanityClient | null } = {
  default: null,
  preview: null,
};

export function getClient(previewToken?: string): SanityClient | null {
  // Return cached instance if it exists
  if (!previewToken && clientInstance.default) return clientInstance.default;
  if (previewToken && clientInstance.preview) return clientInstance.preview;

  if (!projectId) return null;

  const client = createClient({
    projectId,
    dataset,
    apiVersion,
    useCdn: !previewToken,
    perspective: previewToken ? "previewDrafts" : "published",
    stega: {
      enabled: previewToken ? true : false,
      studioUrl: "/studio",
    },
    token: previewToken,
  });

  // Cache the instance
  if (previewToken) {
    clientInstance.preview = client;
  } else {
    clientInstance.default = client;
  }

  return client;
}

export const fetcher = async ([query, params]) => {
  const client = getClient();
  return client ? client.fetch(query, params) : [];
};

export async function getAllPosts() {
  const client = getClient();
  if (client) {
    return (await client.fetch(postquery)) || [];
  }
  return [];
}

export async function getSettings() {
  const client = getClient();
  if (client) {
    return (await client.fetch(configQuery)) || [];
  }
  return [];
}

export async function getPostBySlug(slug) {
  const client = getClient();
  if (client) {
    return (await client.fetch(singlequery, { slug })) || {};
  }
  return {};
}

export async function getAllPostsSlugs() {
  const client = getClient();
  if (client) {
    const slugs = (await client.fetch(pathquery)) || [];
    return slugs.map((slug) => ({ slug }));
  }
  return [];
}
// Author
export async function getAllAuthorsSlugs() {
  const client = getClient();
  if (client) {
    const slugs = (await client.fetch(authorsquery)) || [];
    return slugs.map((slug) => ({ author: slug }));
  }
  return [];
}

export async function getAuthorPostsBySlug(slug) {
  const client = getClient();
  if (client) {
    return (await client.fetch(postsbyauthorquery, { slug })) || {};
  }
  return {};
}

export async function getAllAuthors() {
  const client = getClient();
  if (client) {
    return (await client.fetch(allauthorsquery)) || [];
  }
  return [];
}

// Category

export async function getAllCategories() {
  const client = getClient();
  if (client) {
    const slugs = (await client.fetch(catpathquery)) || [];
    return slugs.map((slug) => ({ category: slug }));
  }
  return [];
}

export async function getPostsByCategory(slug) {
  const client = getClient();
  if (client) {
    return (await client.fetch(postsbycatquery, { slug })) || {};
  }
  return {};
}

export async function getTopCategories() {
  const client = getClient();
  if (client) {
    return (await client.fetch(catquery)) || [];
  }
  return [];
}

export async function getPaginatedPosts({ limit, pageIndex = 0 }) {
  const client = getClient();
  if (client) {
    return (
      (await client.fetch(paginatedquery, {
        pageIndex: pageIndex,
        limit: limit,
      })) || []
    );
  }
  return [];
}

export async function searchPosts(query = "") {
  const client = getClient();
  if (client) {
    return (
      (await client.fetch(searchquery, {
        query: query as any,
      })) || []
    );
  }
  return [];
}
